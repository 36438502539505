import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import ProtectedRoute from './components/ProtectedRoute'
import { CSpinner } from '@coreui/react-pro'
import './scss/style.scss'

const Login = React.lazy(() => import('./views/pages/login/Login'))

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

class App extends Component {
  render() {
    return (
      <HashRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route element={<ProtectedRoute />}>
              <Route path="*" name="Home" element={<DefaultLayout />} />
            </Route>
            <Route exact path="/login" name="Вход" element={<Login />} />
          </Routes>
        </Suspense>
      </HashRouter>
    )
  }
}

export default App
