import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode } from 'jwt-decode'

import { userLogin, userGet, getUsers } from './userActions'

// initialize userToken from local storage
const authTokens = localStorage.getItem('authTokens')
  ? JSON.parse(localStorage.getItem('authTokens'))
  : null

const initialState = {
  currentUser: !authTokens ? {} : jwtDecode(authTokens.access),
  loading: false,
  authTokens, // for JWT tokens
  error: null,
  success: false, // for monitoring the registration process.
  users: [],
  loaded: false,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem('authTokens') // deletes token from storage
      state.loading = false
      state.authTokens = null
      state.error = null
      state.currentUser = {}
      localStorage.removeItem('cart') // очистка корзины при выходе
    },
    setTokens: (state, { payload }) => {
      state.authTokens = payload
      localStorage.setItem('authTokens', JSON.stringify(payload))
      state.error = null
      state.loading = false
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(userLogin.pending, (state) => {
        state.loading = true
        state.error = null
        state.currentUser = {}
      })
      .addCase(userLogin.fulfilled, (state, { payload }) => {
        localStorage.setItem('authTokens', JSON.stringify(payload))
        state.loading = false
        state.authTokens = payload
        state.error = null
        state.currentUser = jwtDecode(payload.access)
      })
      .addCase(userLogin.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.currentUser = {}
      })
      .addCase(userGet.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(userGet.fulfilled, (state, { payload }) => {
        if (!state.users.find((u) => u.id === payload.id)) {
          state.users.push(payload)
        }
        state.loading = false
        state.error = null
      })
      .addCase(userGet.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true
        state.error = null
        state.loaded = false
      })
      .addCase(getUsers.fulfilled, (state, { payload }) => {
        state.users = payload
        state.loading = false
        state.error = null
        state.loaded = true
      })
      .addCase(getUsers.rejected, (state, { payload }) => {
        state.loading = false
        state.error = payload
        state.loaded = false
      })
  },
})

export const { logout, setTokens } = userSlice.actions
export default userSlice.reducer
