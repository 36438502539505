import { createSlice } from '@reduxjs/toolkit'
import {
  getWorkstations,
  saveWorkstation,
  createWorkstation,
  deleteWorkstation,
} from './workstationActions'

const initialState = {
  workstations: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedWorkstation: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const workstationsSlice = createSlice({
  name: 'workstations',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedWorkstation = {}

      state.deleting = false
      state.deleted = false

      state.starting = false
      state.stopping = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getWorkstations.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.workstations = payload
      })
      .addCase(getWorkstations.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getWorkstations.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveWorkstation.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveWorkstation.fulfilled, (state, { payload }) => {
        state.error = null
        state.workstations[state.workstations.findIndex((p) => p.id === payload.id)] = payload
        state.savedWorkstation = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveWorkstation.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createWorkstation.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createWorkstation.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedWorkstation = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createWorkstation.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteWorkstation.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteWorkstation.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteWorkstation.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = workstationsSlice.actions
export default workstationsSlice.reducer
