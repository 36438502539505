import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getWorkstations = createAsyncThunk('workstations/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/workstations/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveWorkstation = createAsyncThunk(
  'workstation/save',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      // const config = { headers: { 'content-type': 'multipart/form-data' } }
      const { data } = await axios.put(`/api/workstations/${t.id}/`, t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createWorkstation = createAsyncThunk(
  'workstation/create',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/workstations/', t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteWorkstation = createAsyncThunk(
  'workstation/delete',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/workstations/${t.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
