import { configureStore } from '@reduxjs/toolkit'
import userReducer from './features/user/userSlice'
import workstationReducer from './features/workstation/workstationSlice'
import applicationReducer from './features/application/applicationSlice'

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
  sidebarUnfoldable: null,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = configureStore({
  reducer: {
    ui: changeState,
    user: userReducer,
    workstations: workstationReducer,
    applications: applicationReducer,
  },
  /*middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware().concat(createLogger())
  ]*/
})

export default store
