import useAxios from '../../utils/useAxios'
import { createAsyncThunk } from '@reduxjs/toolkit'

export const getApplications = createAsyncThunk('applications/get', async (...args) => {
  try {
    const axios = useAxios()
    const { data } = await axios.get('/api/applications/')
    return data
  } catch (error) {
    const { rejectWithValue } = args[1]
    // return custom error message from API if any
    if (error.response && error.response.data.message) {
      return rejectWithValue(error.response.data.message)
    } else {
      return rejectWithValue(error.message)
    }
  }
})

export const saveApplication = createAsyncThunk(
  'application/save',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      // const config = { headers: { 'content-type': 'multipart/form-data' } }
      const { data } = await axios.put(`/api/applications/${t.id}/`, t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const createApplication = createAsyncThunk(
  'application/create',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.post('/api/application/', t)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)

export const deleteApplication = createAsyncThunk(
  'application/delete',
  async (t, { rejectWithValue }) => {
    try {
      const axios = useAxios()
      const { data } = await axios.delete(`/api/applications/${t.id}/`)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message)
      } else {
        return rejectWithValue(error.message)
      }
    }
  },
)
