import { createSlice } from '@reduxjs/toolkit'
import {
  getApplications,
  saveApplication,
  createApplication,
  deleteApplication,
} from './applicationActions'

const initialState = {
  applications: [],
  loading: false,
  error: false,
  loaded: false,
  saving: false,
  saved: false,
  savedApplication: {},
  deleting: false,
  deleted: false,
}

/*function isRejectedAction(action) {
  return action.type.endsWith('rejected')
}*/

const applicationsSlice = createSlice({
  name: 'applications',
  initialState,
  reducers: {
    clear: (state) => {
      state.loading = false
      state.loaded = false

      state.saving = false
      state.error = false
      state.saved = false
      state.savedApplication = {}

      state.deleting = false
      state.deleted = false

      state.starting = false
      state.stopping = false
    },
  },

  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder
      .addCase(getApplications.fulfilled, (state, { payload }) => {
        state.loading = false
        state.loaded = true
        state.error = null
        state.applications = payload
      })
      .addCase(getApplications.pending, (state) => {
        state.loading = true
        state.loaded = false
        state.error = null
      })
      .addCase(getApplications.rejected, (state, { payload }) => {
        state.loading = false
        state.loaded = false
        state.error = payload
      })
      .addCase(saveApplication.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(saveApplication.fulfilled, (state, { payload }) => {
        state.error = null
        state.applications[state.applications.findIndex((p) => p.id === payload.id)] = payload
        state.savedApplication = payload
        state.saved = true
        state.saving = false
      })
      .addCase(saveApplication.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(createApplication.pending, (state) => {
        state.saving = true
        state.error = null
        state.saved = false
      })
      .addCase(createApplication.fulfilled, (state, { payload }) => {
        state.error = null
        state.savedApplication = payload
        state.saved = true
        state.saving = false
      })
      .addCase(createApplication.rejected, (state, { payload }) => {
        state.saving = false
        state.error = payload
        state.saved = false
      })
      .addCase(deleteApplication.pending, (state) => {
        state.deleting = true
        state.error = null
        state.deleted = false
      })
      .addCase(deleteApplication.fulfilled, (state) => {
        state.error = null
        state.deleted = true
        state.deleting = false
      })
      .addCase(deleteApplication.rejected, (state, { payload }) => {
        state.deleting = false
        state.error = payload
        state.deleted = false
      })
  },
})

export const { clear } = applicationsSlice.actions
export default applicationsSlice.reducer
